import * as pfids from './pfids.js';
import {
  processHostingProducts,
  processMSSLProducts,
  processWebsiteBuilderProducts,
} from './helpers';
import { isMultiSitePlan } from '../../../helpers/ApiHelper.js';
const NEW_ACCOUNT_LABEL = 'New Account';

/**
 * @extends {Array}
 */
class SubscriptionsList extends Array {
  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withActiveProducts() {
    return new SubscriptionsList(
      ...this.filter((s) => s.label !== null && s.label !== NEW_ACCOUNT_LABEL),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withHostingProducts() {
    const prods = this.filter(
      (s) =>
        (s.product !== null &&
          s.product.productGroupKey === 'hosting' &&
          ['ACTIVE', 'FREE'].includes(s.status)) ||
        (s.product.productGroupKey === 'wordpress' &&
          s.product.namespace === 'wpaas' &&
          ['ACTIVE', 'FREE'].includes(s.status)) ||
        (s.status === 'TRIAL PERIOD' && new Date(s.expiresAt) >= new Date()) ||
        (s.product.productGroupKey === 'servers' && s.status === 'ACTIVE'),
    );

    return new SubscriptionsList(...processHostingProducts(prods));
  }

  withWebsiteMarketing() {
    const prods = this.filter(
      (s) =>
        s.product.productGroupKey === 'websiteBuilder' &&
        s.status === 'ACTIVE' &&
        s.label !== 'New Account' &&
        !s.label.includes('godaddysites.com'),
    );

    return new SubscriptionsList(...processWebsiteBuilderProducts(prods));
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withWebSecurity() {
    return new SubscriptionsList(
      ...this.filter((s) => {
        return pfids.WSS.has(s.product.pfid);
      }),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withWebSecurityMultiSite() {
    return new SubscriptionsList(
      ...this.filter((s) => {
        return pfids.WSS.has(s.product.pfid) && isMultiSitePlan(s.product.pfid);
      }),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  inactive() {
    return new SubscriptionsList(
      ...this.filter((s) => {
        return s.label === NEW_ACCOUNT_LABEL || s.label === null;
      }),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withBackups() {
    return new SubscriptionsList(
      ...this.filter((s) => {
        return pfids.BACKUPS.has(s.product.pfid);
      }),
    );
  }

  /**
   * @param {Array} tickets An array of tickets from the MSSL API
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withInactiveMSSL(tickets) {
    return new SubscriptionsList(
      ...this.filter((s) => {
        const isMSSL = pfids.MSSL.has(s.product.pfid);

        if (!isMSSL) {
          return false;
        }

        const hasMatchingTicket = tickets.some((t) => {
          return t.subscription_id === s.subscriptionId;
        });

        return !hasMatchingTicket;
      }),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withSSL() {
    return new SubscriptionsList(
      ...this.filter((s) => pfids.STANDARD_SSL.has(s.product.pfid)),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withSSLNoEvOv() {
    return new SubscriptionsList(
      ...this.filter(
        (s) =>
          pfids.STANDARD_SSL.has(s.product.pfid) &&
          !pfids.SSL_OV_EV.has(s.product.pfid),
      ),
    );
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withManagedSSL() {
    const msslSubs = this.filter((s) => {
      const addonIsMssl =
        s.addons &&
        s.addons.find((addon) => pfids.MSSL.has(parseInt(addon.pfid, 10)));
      return pfids.MSSL.has(s.product.pfid) || addonIsMssl;
    });

    return new SubscriptionsList(...processMSSLProducts(msslSubs));
  }

  /**
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withUltimateMWP() {
    const UMWPSubs = this.filter((s) => pfids.ULTIMATE_MWP.has(s.product.pfid));

    return new SubscriptionsList(...processMSSLProducts(UMWPSubs));
  }

  /**
   * @param {string} domain A domain name to filter by
   * @returns {SubscriptionsList} A modified SubscriptionsList
   */
  withDomain(domain) {
    return new SubscriptionsList(
      ...this.filter((s) => {
        return s.label === domain;
      }),
    );
  }

  /**
   * @returns {Object.<number, SubscriptionsList>} A map with keys being unique
   * PFID numbers and values being a SubscriptionsList
   */
  groupByProductID() {
    var output = {};

    this.forEach((i) => {
      if (!Object.prototype.hasOwnProperty.call(output, i.product.pfid)) {
        output[i.product.pfid] = new SubscriptionsList();
      }

      output[i.product.pfid].push(i);
    });

    return output;
  }
}

export default SubscriptionsList;
