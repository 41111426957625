// flags.js
import { useContext, useEffect } from 'react';
import Router from 'next/router';

import flags from '../common/flags';
import { ConfigContext } from './config';

const useFlag = (key, opts) => {
  const ctx = useContext(ConfigContext);

  if (!opts || !opts.rawValue) {
    return ctx && (ctx[key] === true || ctx[key] === 'on');
  }

  return ctx && ctx[key];
};

const withFlagProtected = (Component, flag) => {
  const WrappedComponent = (props) => {
    const isEnabled = useFlag(flag);

    useEffect(() => {
      if (isEnabled) {
        return;
      }

      Router.push('/404');
    }, [isEnabled]);

    if (!isEnabled) {
      return null;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

/**
 * Higher-order component that wraps a given component and checks if a feature flag is enabled or if the context is Sucuri.
 * @param {React.ComponentType} Component - The component to wrap.
 * @param {string} flag - The feature flag to check.
 *
 * @returns {React.ComponentType} - The wrapped component.
 */
const withFlagProtectedOrAllowedReseller = (Component, flag) => {
  const WrappedComponent = (props) => {
    const ctx = useContext(ConfigContext);
    const allowedResellers = ctx.secui_my_sites_v2_plids.split(',');
    const isEnabled =
      useFlag(flag) || allowedResellers.includes(String(ctx.info.info_plid));
    useEffect(() => {
      if (isEnabled) {
        return;
      }

      Router.push('/404');
    }, [isEnabled]);

    if (!isEnabled) {
      return null;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

module.exports = {
  ...flags,
  useFlag,
  withFlagProtected,
  withFlagProtectedOrAllowedReseller,
};
